<template>
    <v-container fluid class="tw-bg-white tw-flex tw-h-screen">
        <v-card flat class="content tw-pl-4 tw-w-full">
            <div class="tw-mx-auto" style="max-width: 990px;">
                <h1 class="text-h4 mt-4 mb-6">{{ article.title }}</h1>
                <template v-if="article.format === 'json'">            
                    <template v-for="(item, index) in article.body.blocks">
                        <div 
                            v-if="item.type === 'header' && item.data.level === 2" 
                            :key="`proxy${item.type + index}`"
                            class="tw-invisible tw-relative"
                            style="top: -80px"
                            :id="slugify(item.data.text)"
                        ></div>
                        <h2 
                            v-if="item.type === 'header' && item.data.level === 2" 
                            :key="item.type + index"
                            class="text-h5 mb-2"
                            v-html="item.data.text"
                        ></h2>
                        <h3 
                            v-if="item.type === 'header' && item.data.level === 3" 
                            :key="item.type + index"
                            class="text-h6 mb-2"
                            v-html="item.data.text"
                        ></h3>
                        <p 
                            v-if="item.type === 'paragraph'" 
                            :key="item.type + index"
                            v-html="parseHTML(item.data.text)"
                        ></p>
                        <div 
                            v-if="item.type === 'image'" 
                            :key="item.type + index"
                        >
                            <img :src="item.data.file.url" class="tw-max-w-full"/>
                        </div>
                        <div 
                            v-if="item.type === 'raw'" 
                            :key="item.type + index"
                            v-html="item.data.html"
                        ></div>
                        <a
                            v-if="item.type === 'attachment'" 
                            :key="item.type + index"
                            class="mb-3 d-flex tw-border tw-border-gray-400 tw-border-solid tw-p-3"
                            :href="item.data.file.url"
                            download
                            title="Download"
                        >
                            <v-icon 
                                v-if="item.data.file.extension === 'pdf'"
                                large
                            >fal fa-file-pdf</v-icon>
                            <v-icon 
                                v-else-if="item.data.file.extension === 'jpg' || item.data.file.extension === 'png' || item.data.file.extension === 'gif'"
                                large
                            >fal fa-file-image</v-icon>
                            <v-icon 
                                v-else
                                large
                            >fal fa-file-alt</v-icon>
                            <div class="mx-4 tw-flex-1">
                                <div>{{ item.data.title }}</div>
                                <div class="tw-text-gray-600 tw-text-sm">{{ fileSizeForHuman(item.data.file.size) }}</div>
                            </div>
                            <v-icon>fal fa-download fa-lg</v-icon>
                        </a>
                        <v-simple-table
                            v-if="item.type === 'table'"
                            :key="item.type + index"
                            class="mb-6"
                        >
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                        v-for="(tr, trIndex) in item.data.content"
                                        :key="tr[0] + trIndex"
                                    >
                                        <td
                                            v-for="(td, tdIndex) in tr"
                                            :key="td[0] + tdIndex"
                                            v-html="td"
                                        ></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div 
                            v-if="item.type === 'checklist'" 
                            :key="item.type + index"
                            class="mb-4"
                        >
                            <div 
                                v-for="(clitem, clIndex) in item.data.items"
                                :key="clitem.text + clIndex"
                                class="d-flex align-center mb-2"
                            >
                                <v-icon v-if="clitem.checked" color="primary" dense>fal fa-check-circle</v-icon>
                                <v-icon v-else dense>fal fa-circle</v-icon>
                                <div class="ml-2">{{ clitem.text }}</div>
                            </div>
                        </div>
                        <ol
                            v-if="item.type === 'list' && item.data.style === 'ordered'"
                            :key="item.type + index"
                            class="mb-4"
                        >
                            <li v-for="list in item.data.items" :key="list" v-html="list"></li>
                        </ol>
                        <ul
                            v-if="item.type === 'list' && item.data.style === 'unordered'"
                            :key="item.type + index"
                            class="mb-4"
                        >
                            <li v-for="list in item.data.items" :key="list" v-html="list"></li>
                        </ul>
                        <div
                            :key="item.type + index"
                            v-if="item.type === 'embed' && item.data.service === 'youtube'"
                        >
                            <div class="iframe-wrapper">
                                <iframe                            
                                    width="100%"
                                    height="auto"
                                    :src="item.data.embed" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen
                                ></iframe>                        
                            </div>
                            <div class="mt-1">{{ item.data.caption }}</div>
                        </div>
                    </template>
                </template>
                <div v-if="article.format === 'html'" v-html="article.body[0]"></div>
            </div>
        </v-card>
        <v-navigation-drawer
            floating
            permanent
            right
            clipped
            class="tw-mt-20 md:tw-fixed"
            v-if="article.format === 'json' && headers.length > 1"
        >
            <v-list dense rounded>
                <v-list-item
                    v-for="item in headers"
                    :key="item.data.text"
                    :to="{
                        name: 'articles.show',
                        params: { id: article.slug },
                        hash: `#${slugify(item.data.text)}`
                    }"
                    active
                >
                    <v-list-item-content>
                        <v-list-item-title v-html="item.data.text"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import Http from '@/utils/httpClient';
import slugify from '@/utils/slugify';

export default {
    data() {
        return {
            article: {
                body: {
                    blocks: []
                }
            }
        };
    },
    computed: {
        headers() {
            return this.article.body.blocks.filter(
                (item) => item.type === 'header' && item.data.level === 2
            );
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$root.$loading.open();
            Http.get(`articles/${this.$route.params.id}/show`, {
                params: {
                    query: {
                        article: [
                            'body',
                            'title',
                            'format',
                        ]
                    }
                }
            })
            .then(({ data }) => {
                this.$root.$loading.close();
                this.article = data.data;
                document.title = data.data.title;                        
            })
            .catch(() => this.$root.$loading.close())
        },
        parseHTML(text) {
            const e = document.createElement('div');
            e.innerHTML = this.htmlEntities(text);

            return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
        },
        htmlEntities(str) {
            return String(str)
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;');
        },
        slugify(str) {
            return slugify(str);
        },
        fileSizeForHuman(size) {
            if (Math.log10(+size) >= 6) {
                return `${(size / (2 ** 20)).toFixed(1)} MB`;
            }

            return `${(size / (2 ** 10)).toFixed(1)} KB`;
        }
    }
};
</script>

<style lang="scss" scoped>
@screen md {
    .content {
        padding-right: 256px;
    }
}
</style>
